<template>
  <div>
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/success/success.png"
        alt=""
    />
    <div>正在审核中…</div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  created() {
    localStorage.setItem('isSuccess', '1')
  }
}
</script>

<style scoped>
img {
  width: 4.533rem;
  margin: 2.747rem auto 0;
}

div {
  text-align: center;
  font-size: 0.427rem;
  color: #333333;
}
</style>